import React, { useEffect, useState } from 'react';
import { FiltersHeader } from './FiltersHeader';
import { FiltersBody } from './FiltersBody';
import {
  ApplicationListingFilters,
  DEFAULT_FILTER_STATE,
  FilterType,
  getAvailableFilters,
} from './utils';
import { mapSearchSegmentToFilters } from './utils/mapSearchSegmentToFilters';
import { AlertObject } from '../../../../../../components/Alert';
import { SearchSegmentService } from '../../../../../../services/v1/applicant_tracking/SearchSegmentService';
import { SearchSegment } from '../../../../../../entities/v1/applicant_tracking/SearchSegment';
import { getQueryParam } from '../../../../../../utils/url';
import { SEARCH_PARAM } from '../../OpenSearch';
import { SEGMENT_PARAM } from './FiltersHeader/SegmentSelect';

interface PropTypes {
  atsTagsPanelEnabled: boolean;
  filters: ApplicationListingFilters;
  filterTypes: FilterType[];
  onSearch: () => void;
  setAlert: (alert: AlertObject) => void;
  setFilters: (filters: ApplicationListingFilters) => void;
  setFilterTypes: (filterTypes: FilterType[]) => void;
}

function isNullOrUndefinedOrEmptyArray(value) {
  return value == null || (Array.isArray(value) && value.length === 0);
}

function activateSelectedFilters(
  filters: ApplicationListingFilters,
  filterTypes: FilterType[],
  setFilterTypes: (filterTypes: FilterType[]) => void,
  atsTagsPanelEnabled: boolean,
) {
  const OPTIONAL_FILTERS_TO_CHECK = [
    'name',
    'email',
    'location',
    'channels',
    'sources',
    'rightToWorkLocations',
    'resume',
    'tags',
  ];

  // This logic shall be reverted when we remove the atsTagsPanelEnabled feature flag
  const availableFilters = getAvailableFilters(atsTagsPanelEnabled);

  const validOptionalFilters = OPTIONAL_FILTERS_TO_CHECK.filter(
    (filter) => !isNullOrUndefinedOrEmptyArray(filters[filter]),
  );

  const newFilterTypes = [
    ...filterTypes,
    ...validOptionalFilters.map((filter) =>
      availableFilters.find((type) => type.value === filter),
    ),
  ].filter(
    (item, index, self) =>
      index === self.findIndex((i) => i.value === item.value),
  );

  setFilterTypes(newFilterTypes);
}

async function reloadSegments(
  setSegments: (segments: SearchSegment[]) => void,
): Promise<SearchSegment[]> {
  const segmentList = await SearchSegmentService.list();
  setSegments(segmentList.searchSegments);

  return segmentList.searchSegments;
}

export function ListApplicationsFilters(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [segments, setSegments] = useState<SearchSegment[]>([]);

  const reloadFilters = async (segment?: SearchSegment) => {
    if (segment) {
      mapSearchSegmentToFilters(segment).then((filters) => {
        activateSelectedFilters(
          filters,
          props.filterTypes,
          props.setFilterTypes,
          props.atsTagsPanelEnabled,
        );
        props.setFilters(filters);
      });
    } else {
      props.setFilters({ ...DEFAULT_FILTER_STATE, statuses: null });
    }
  };

  const changeToDefault = () => {
    const defaultSegment = segments?.find((i) => i.isDefault);

    if (selectedSegment === defaultSegment) {
      reloadFilters(defaultSegment);
    } else {
      setSelectedSegment(defaultSegment);
    }
  };

  useEffect(() => {
    if (!selectedSegment) return;

    setIsLoading(true);

    reloadFilters(selectedSegment).finally(() => setIsLoading(false));
  }, [selectedSegment]);

  useEffect(() => {
    reloadSegments(setSegments)
      .then((segments) => {
        const param = getQueryParam(SEGMENT_PARAM);
        const segmentParameter =
          param && segments.find((i) => i.id.toString() === param);

        if (!getQueryParam(SEARCH_PARAM)) {
          setSelectedSegment(
            segmentParameter || segments.find((i) => i.isDefault),
          );
        } else {
          if (segmentParameter) {
            setSelectedSegment(segmentParameter);
          } else {
            reloadFilters(segmentParameter);
          }
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className='p-4'>
      <FiltersHeader
        atsTagsPanelEnabled={props.atsTagsPanelEnabled}
        isFiltersOpen={isFiltersOpen}
        filters={props.filters}
        filterTypes={props.filterTypes}
        segments={segments}
        selectedSegment={selectedSegment}
        setSelectedSegment={setSelectedSegment}
        setFilterTypes={props.setFilterTypes}
        setIsFiltersOpen={setIsFiltersOpen}
      />
      {isFiltersOpen && (
        <FiltersBody
          filters={props.filters}
          filterTypes={props.filterTypes}
          isLoading={isLoading}
          onSearch={props.onSearch}
          setFilters={props.setFilters}
          setAlert={props.setAlert}
          onCreateSegment={(segment) => {
            reloadSegments(setSegments).then((_a) =>
              setSelectedSegment(segment),
            );
          }}
          selectDefaultSegment={changeToDefault}
        />
      )}
    </div>
  );
}
