import React, { useState } from 'react';
import { appendQueryParam, getQueryParam } from '../../../../../utils/url';
import { SpanTooltip } from '../../../../../components/SpanTooltip';
import { Icon } from '../../../../../components/Icon';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Col, Row } from 'reactstrap';

export const SEARCH_PARAM = 'search';

interface PropTypes {
  url: string;
}

function AdvancedSearchTooltip() {
  return (
    <SpanTooltip
      tooltipInnerClassName={classNames(
        styles['open-search-tooltip'],
        'text-primary',
      )}
      tooltipText={
        <>
          <span>
            <b>Advanced Search</b>
          </span>
          <ul>
            <li>
              Use AND or OR to include or exclude specific terms from your
              search;
            </li>
            <li>
              Use ( ) to group terms or apply specific rules to your search;
            </li>
            <li>
              Fields supported in open search: Name, E-mail, Location, Job and
              CV.
            </li>
          </ul>
        </>
      }
      text={
        <Icon
          name='bi-info-circle'
          className='text-info fs-4'
          style={{ cursor: 'pointer' }}
        />
      }
      popperClassName='light-tooltip ms-3'
    />
  );
}

export default function OpenSearch(props: PropTypes) {
  const [searchText, setSearchText] = useState<string>(
    getQueryParam(SEARCH_PARAM) ?? '',
  );

  return (
    <Row className='g-2 flex-nowrap justify-content-start justify-content-md-end align-items-center'>
      <Col xs='auto'>
        <form
          className='row gx-1 align-items-center'
          onSubmit={(ev) => {
            window.location.href = appendQueryParam(
              props.url,
              SEARCH_PARAM,
              searchText,
            );
            ev.preventDefault();
          }}
        >
          <div className='col-auto'>
            <input
              type='text'
              name='open_search'
              value={searchText}
              onChange={(ev) => setSearchText(ev.target.value)}
              placeholder='Search Candidates by Name, Email, Job, Location or CV'
              className={classNames(
                'form-control',
                'search-input',
                styles['open-search'],
              )}
            />
          </div>
        </form>
      </Col>
      <Col xs='auto'>
        <AdvancedSearchTooltip />
      </Col>
    </Row>
  );
}
